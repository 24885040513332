import React, { FunctionComponent, useEffect, useState } from "react";
import { ICustomField } from "../../interfaces/models/customFields";
import Column from "../../components/Column";
import Text from "../../components/Text";
import Input from "../../components/Input";
import Select from "../../components/select/Select";
import styled from "styled-components";
import Button from "../../components/Button";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";
import FormControl from "../../components/FormControl";
import { useHubspotWebhook } from "../../services/apis/hubspot/useHubspotWebhook";
import { TriggerObject } from "../../interfaces/models/hubspot";
import { useHubspotHandlers } from "../../services/actions/hubspot/useHubspotHandlers";

interface IHubspotIntegrationFormProps {
    editTrigger?: TriggerObject
}

const HubspotIntegrationForm: FunctionComponent<IHubspotIntegrationFormProps> = ({editTrigger}) => {
    const { closeSidebar, showSpinner, hideSpinner } = useAppHandlers()
    const { addHubspotTriggerHandler, editHubspotTriggerHandler } = useHubspotHandlers()

    const options = [
        {
            label: 'Tag apply -> Create/update contact',
            value: 'tag-apply-contact-create-update',
            event: 'tag-apply',
            action: 'contact-create-update',
            labelValue1: 'Tag title',
            component: 
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Tag apply -> Create/update contact">{'Tag apply -> Create/update contact'}</Text>
                </div>
        }, 
        {
            label: 'Tag apply -> Add contact to lists',
            value: 'tag-apply-contact-add-to-lists',
            event: 'tag-apply',
            action: 'contact-add-to-lists',
            labelValue1: 'Tag title',
            labelValue2: 'Lists (with comma separated values)',
            component: 
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Tag apply -> Add contact to lists">{'Tag apply -> Add contact to lists'}</Text>
                </div>
        }, 
        {
            label: 'Tag apply -> Add contact to workflow',
            value: 'tag-apply-contact-add-to-workflow',
            event: 'tag-apply',
            action: 'contact-add-to-workflow',
            labelValue1: 'Tag title',
            labelValue2: 'Workflow',
            component: 
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Tag apply -> Add contact to workflow">{'Tag apply -> Add contact to workflow'}</Text>
                </div>
        }, 
        {
            label: 'Tag apply -> Create new deal',
            value: 'tag-apply-deal-create',
            event: 'tag-apply',
            action: 'deal-create',
            labelValue1: 'Tag title',
            component: 
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Tag apply -> Create new deal">{'Tag apply -> Create new deal'}</Text>
                </div>
        }, 

        {
            label: 'Note new -> Create note for the contact',
            value: 'note-new-note-create-for-contact',
            event: 'note-new',
            action: 'note-create-for-contact',
            component: 
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Note new -> Create note for the contact">{'Note new -> Create note for the contact'}</Text>
                </div>
        }, 

        {
            label: 'Connection new -> Add contact to workflow',
            value: 'connection-new-contact-add-to-workflow',
            event: 'connection-new',
            action: 'contact-add-to-workflow',
            labelValue1: 'Workflow',
            component: 
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Connection new -> Add contact to workflow">{'Connection new -> Add contact to workflow'}</Text>
                </div>
        }, 
        {
            label: 'Connection new -> Add contact to lists',
            value: 'connection-new-contact-add-to-lists',
            event: 'connection-new',
            action: 'contact-add-to-lists',
            labelValue1: 'Lists (with comma separated values)',
            component:
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Connection new -> Add contact to lists">{'Connection new -> Add contact to lists'}</Text>
                </div>
        }, 
        {
            label: 'Connection new -> Create/update contact',
            value: 'connection-new-contact-create-update',
            event: 'connection-new',
            action: 'contact-create-update',
            component: 
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Connection new -> Create/update contact">{'Connection new -> Create/update contact'}</Text>
                </div>
        }, 

        { 
            label: 'Connection disconnected -> Remove contact from lists',
            value: 'connection-disconnected-contact-remove-from-lists',
            event: 'connection-disconnected',
            action: 'contact-remove-from-lists',
            labelValue1: 'Lists',
            component:
                <div style={{width: '250px'}}>
                    <Text $lighter $ellipsis="0" tooltipMessage="Connection disconnected -> Remove contact from lists">{'Connection disconnected -> Remove contact from lists'}</Text>
                </div>
        } 
    ]

    const [trigger, setTrigger] = useState<TriggerObject>({
        name: '',
        action: '',
        event: '',
        value: '',
        labelValue1: '',
        value1: '',
        labelValue2: '',
        value2: ''
    })

    useEffect(() => {
        if(editTrigger) {
            const [value1, value2] = (editTrigger.value || '').split(';').map(item => item.trim())
            const selected = options.find((item: any) => item.event === editTrigger.event && item.action === editTrigger.action)

            setTrigger({
                ...editTrigger,
                value1: value1,
                value2: value2,
                labelValue1: selected?.labelValue1 ?? '',
                labelValue2: selected?.labelValue2 ?? ''
            })
        }
    }, [editTrigger])

    const addEditTriggerHandler = async(trigger: TriggerObject) => {
        closeSidebar(SidebarTypes.HUBSPOT)

        let body = {
            name: trigger.name,
            action: trigger.action,
            event: trigger.event,
            value: [trigger.value1, trigger.value2].filter(Boolean).join('; ')
        }

        if(editTrigger) {
            await editHubspotTriggerHandler({ id: editTrigger._id, body })    
        } else {
            await addHubspotTriggerHandler(body)
        }
    }

    return (
        <Column gap="10px">
            <FormControl label="Trigger Name">
                <Input
                    placeholder="Name..."
                    prefilledValue={trigger.name}
                    onChangeHandler={(e: any) => setTrigger({...trigger, name: e})}
                />
            </FormControl>
            <FormControl label="Choose Event, Action">
                <Select 
                    $components
                    placeholder="Choose event, action"
                    isSearchable={false}
                    options={options}
                    value={editTrigger && options.find((item: any) => item.event === trigger.event && item.action === trigger.action)}
                    onChange={(e: any) => setTrigger({...trigger, ...e})}
                />
            </FormControl>
            {trigger.labelValue1 &&
                <FormControl label={trigger.labelValue1}>
                    <Input 
                        placeholder={trigger.labelValue1} 
                        prefilledValue={trigger.value1} 
                        onChangeHandler={(e: any) => setTrigger({...trigger, value1: e})} 
                    />
                </FormControl>
            }
            {trigger.labelValue2 &&
                <FormControl label={trigger.labelValue2}>
                    <Input 
                        placeholder={trigger.labelValue2} 
                        prefilledValue={trigger.value2} 
                        onChangeHandler={(e: any) => setTrigger({...trigger, value2: e})} 
                    />
                </FormControl>
            }
            <ButtonWrapper>
                <Button $type="cancel" onClickHandler={() => closeSidebar(SidebarTypes.HUBSPOT)}>Cancel</Button>
                <Button $type="blue" onClickHandler={() => addEditTriggerHandler(trigger)}>{editTrigger ? 'Save' : 'Add'}</Button>
            </ButtonWrapper>
        </Column>
    )

}

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
`

export default HubspotIntegrationForm

